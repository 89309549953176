
@font-face {
  font-family: "Noto Sans";
  src: url("./resources/fonts/NotoSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./resources/fonts/NotoSans-Bold.ttf");
  font-weight: 700;
}

html {
  font-size: 14px;
}

body {
  background-color: rgb(236, 236, 236);
  margin: 0;
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e3e3e;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
